import { getTranslationFrom } from '../../utils/translate';

const texts = {
  en: {
    'footerButtons.back': 'Back',
    'footerButtons.next': 'Next',
  },
  es: {
    'footerButtons.back': 'Atras',
    'footerButtons.Next': 'Siguiente',
  },
};

export const getTranslatedText = getTranslationFrom(texts);

export default texts;
