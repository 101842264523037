import React, { Fragment } from 'react';
import { Route } from 'react-router-dom';
import PropTypes from 'prop-types';
// Components
import ConfirmRateData from '../../../containers/ConfirmRateData';
import InitialQuote from '../../../containers/InitialQuote';
import Coverages from '../../../containers/Coverages';
import Deductibles from '../../../containers/Deductibles';
import Credits from '../../../containers/Credits';
import FinalizeQuote from '../../../containers/FinalizeQuote';
import Insurer from '../../../containers/Insurer';
import AdditionalCoverges from '../../../containers/AdditionalCoverage';
import AdditionalRequirements from '../../../containers/AdditionalRequirements';
import Summary from '../../../containers/Summary';
// Layout HOCs
import withLayout from '../../withLayout';
import withNavigationMapLayout from '../../withNavigationMapLayout';
// Utils
import Connect from '../../../utils/connect';

// Decorated components
const DecoratedConfirmRouteData = withLayout(withNavigationMapLayout(ConfirmRateData));
const DecoratedInitialQuote = withLayout(withNavigationMapLayout(InitialQuote));
const DecoratedCoverages = withLayout(withNavigationMapLayout(Coverages));
const DecoratedCredits = withLayout(withNavigationMapLayout(Credits));
const DecoratedFinalizeQuote = withLayout(withNavigationMapLayout(FinalizeQuote));
const DecoratedInsurer = withLayout(withNavigationMapLayout(Insurer));
const DecoratedAdditionalRequirements = withLayout(withNavigationMapLayout(AdditionalRequirements));
const hocDeductibles = withLayout(withNavigationMapLayout(Deductibles));
const hocAdditionalCoverages = withLayout(withNavigationMapLayout(AdditionalCoverges));
const DecoratedSummary = withLayout(withNavigationMapLayout(Summary));

const QuoteRoutes = props => {
  const { match } = props;
  return (
    <Fragment>
      <Route exact path={match.url} component={DecoratedInitialQuote} />
      <Route path={`${match.url}/confirm-rate-data`} component={DecoratedConfirmRouteData} />
      <Route path={`${match.url}/coverages`} component={DecoratedCoverages} />
      <Route path={`${match.url}/credits`} component={DecoratedCredits} />
      <Route path={`${match.url}/insurer`} component={DecoratedInsurer} />
      <Route path={`${match.url}/additional-requirements`} component={DecoratedAdditionalRequirements} />
      <Route path={`${match.url}/additional-coverages`} component={hocAdditionalCoverages} />
      <Route path={`${match.url}/deductibles`} component={hocDeductibles} />
      <Route path={`${match.url}/summary`} component={DecoratedSummary} />
      <Route path={`${match.url}/finalize`} component={DecoratedFinalizeQuote} />
    </Fragment>
  );
};

QuoteRoutes.propTypes = {
  match: PropTypes.shape({}).isRequired,
};

export default Connect(QuoteRoutes);
