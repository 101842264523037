import ReactGA from 'react-ga';
import config from '../config';

const {
  google: {
    analytics: { id: gaId },
  },
} = config;

ReactGA.initialize(gaId); // Here we should use our GA id

export default ReactGA;
