import React from 'react';
import PropTypes from 'prop-types';
import shortid from 'shortid';
import { FormGroup, Label, Input, FormFeedback } from 'reactstrap';
import styles from './styles.module.css';

const Options = ({ options, onClickSuggestion }) =>
  options.length > 0 ? (
    <div className={styles.suggestions}>
      {options.map(option => (
        <div
          key={shortid.generate()}
          className={styles.suggestion}
          onClick={() => onClickSuggestion(option)}
          tabIndex="0"
          onKeyDown={e => e}
          role="button"
        >
          {option}
        </div>
      ))}
    </div>
  ) : null;

Options.propTypes = {
  options: PropTypes.instanceOf(Array).isRequired,
  onClickSuggestion: PropTypes.func.isRequired,
};

const InputComponent = ({
  value,
  isValid,
  label,
  captionText,
  options,
  onClickSuggestion,
  ...rest
}) => {
  const inputProps = { valid: isValid, invalid: typeof isValid === 'boolean' ? !isValid : null };
  return (
    <div className={styles['input-wrapper']}>
      <FormGroup>
        {label && <Label for="exampleEmail">{label}</Label>}
        <Input value={value} {...inputProps} {...rest} />
        {captionText && (
          <FormFeedback valid={isValid || undefined} {...rest}>
            {isValid ? captionText.valid : captionText.error}
          </FormFeedback>
        )}
        {/* <FormText>Example help text that remains unchanged.</FormText> */}
        {value && value.length > 0 && (
          <Options onClickSuggestion={onClickSuggestion} options={options} />
        )}
      </FormGroup>
    </div>
  );
};

InputComponent.defaultProps = {
  isValid: null,
  label: '',
  captionText: {},
  options: null,
  onClickSuggestion: null,
};

InputComponent.propTypes = {
  value: PropTypes.string.isRequired,
  isValid: PropTypes.bool,
  label: PropTypes.string,
  captionText: PropTypes.shape({}),
  options: PropTypes.instanceOf(Array),
  onClickSuggestion: PropTypes.func,
};

export default InputComponent;
