import React from 'react';
import PropTypes from 'prop-types';

import classes from './styles.module.css';

const Video = ({ videoSrc, title }) => {
  return (
    <div className={classes.container}>
    </div>
  );
};

Video.propTypes = {
  videoSrc: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default Video;
