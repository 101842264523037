import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
// Components
import Slider from 'rc-slider';
import { FooterButtons } from '../../components';
// HOCs
import withQuoteLayout from '../../components/withQuoteLayout';
// Utils
import { getTranslatedText } from './languages';
import Connect from '../../utils/connect';
// Styles
import styles from './styles.module.css';
import 'rc-slider/assets/index.css';

class Deductibles extends Component {
  componentDidMount() {
    const {
      rdx: {
        action: { setNavigationMapStep },
      },
    } = this.props;
    setNavigationMapStep(4);
  }

  onClickBackButton = () => {
    const {
      history,
      location: { search },
    } = this.props;
    const pathname = 'additional-coverages';
    history.replace({ pathname, search });
  };

  onClickNextButton = () => {
    const {
      history,
      location: { search },
    } = this.props;
    const pathname = 'credits';
    history.push({ pathname, search });
  };

  sliderHurricane(value) {
    const {
      rdx: {
        action: { setHurricane },
      },
    } = this.props;
    setHurricane(value);
  }

  sliderOther(value) {
    const {
      rdx: {
        action: { setPerils },
      },
    } = this.props;
    setPerils(value);
  }

  render() {
    const {
      rdx: {
        state: {
          deductibles: { hurricane, otherPerils },
          global: {
            localization: { lang },
          },
        },
      },
    } = this.props;

    return (
      <div className={styles.content} id="deductible">
        <div className={styles.title}>{getTranslatedText('helper', lang)}</div>
        <SliderComponent
          max={100}
          min={0}
          step={1}
          onChange={e => this.sliderHurricane(e)}
          value={hurricane}
          tag={getTranslatedText('draggable.hurricane', lang)}
        />
        <SliderComponent
          max={10000}
          min={100}
          step={100}
          onChange={e => this.sliderOther(e)}
          value={otherPerils}
          tag={getTranslatedText('draggable.otherPerils', lang)}
        />
        <FooterButtons
          firstButtonText={getTranslatedText('footerButtons.back', lang)}
          secondButtonText={getTranslatedText('footerButtons.next', lang)}
          onClickFirstButton={this.onClickBackButton}
          onClickSecondButton={this.onClickNextButton}
        />
      </div>
    );
  }
}

const SliderComponent = ({ onChange, value, max, min, step, tag }) => {
  const styleSlider = {
    borderColor: '#009EFB',
    width: '20px',
    height: '20px',
    marginTop: '-9px',
  };
  const colorSlider = { backgroundColor: '#009EFB', height: '2px' };
  return (
    <div className={styles['slider-wrapper']}>
      <div className={styles.tag}>{tag}</div>
      <div className={styles.sliderContainer}>
        <Slider
          max={max}
          min={min}
          step={step}
          onChange={onChange}
          value={value}
          handleStyle={styleSlider}
          railStyle={colorSlider}
          trackStyle={colorSlider}
        />
        <div className={styles.follower} style={{ width: `${value / step + 4}%` }}>
          {value}
        </div>
      </div>
    </div>
  );
};

SliderComponent.propTypes = {
  onChange: PropTypes.func.isRequired,
  max: PropTypes.number.isRequired,
  min: PropTypes.number.isRequired,
  step: PropTypes.number.isRequired,
  tag: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
};

Deductibles.propTypes = {
  rdx: PropTypes.shape({}).isRequired,
  location: PropTypes.shape({}).isRequired,
  history: PropTypes.shape({}).isRequired,
};

export default withQuoteLayout(Connect(withRouter(Deductibles)));
