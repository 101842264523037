import { getTranslationFrom } from '../../utils/translate';

const texts = {
  en: {
    languageOptions: 'Languages',
    'languageOptions.english': 'English',
    'languageOptions.spanish': 'Spanish',
  },
  es: {
    languageOptions: 'Idiomas',
    'languageOptions.english': 'Ingles',
    'languageOptions.spanish': 'Español',
  },
};

export const getTranslatedText = getTranslationFrom(texts);

export default texts;
