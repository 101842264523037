import React from 'react';
import PropTypes from 'prop-types';

import { Spinner } from 'reactstrap';

import './styles.scss';

const LoadingScreen = props => {
  const { text, title } = props;
  return (
    <div className="ls-container">
      <h3>{title}</h3>
      <div>{text}</div>
      <Spinner style={{ width: '4em', height: '4em' }} />
    </div>
  );
};

LoadingScreen.propTypes = {
  text: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default LoadingScreen;
