import React, { Component } from 'react';
import PropTypes from 'prop-types';
import shortid from 'shortid';
import { withRouter } from 'react-router-dom';
// Components
import { SelectableRow, FooterButtons } from '../../components';
// HOCs
import withQuoteLayout from '../../components/withQuoteLayout';
// Utils
import { getTranslatedText } from './languages';
import Connect from '../../utils/connect';
// Styles
import styles from './styles.module.css';

class Coverages extends Component {
  state = {};

  componentDidMount() {
    const {
      rdx: {
        action: { setNavigationMapStep },
      },
    } = this.props;
    setNavigationMapStep(3);
  }

  onClickBackButton = () => {
    const {
      history,
      location: { search },
    } = this.props;
    const pathname = '/quote/coverages';
    history.replace({ pathname, search });
  };

  onClickNextButton = () => {
    const {
      history,
      location: { search },
    } = this.props;
    const pathname = 'deductibles';
    history.push({ pathname, search });
  };

  _renderCoverages() {
    const {
      rdx: {
        action: { selectCoverage },
        state: {
          coverages: { additionalCoverages },
        },
      },
    } = this.props;

    return additionalCoverages.map(({ name, selected }, index) => (
      <SelectableRow
        text={name}
        key={shortid.generate()}
        onClick={() => selectCoverage({ arraySelector: 'additionalCoverages', index })}
        selected={selected}
      />
    ));
  }

  render() {
    const {
      rdx: {
        state: {
          global: {
            localization: { lang },
          },
        },
      },
    } = this.props;

    return (
      <div className={styles.content}>
        <div className={styles.title}>{getTranslatedText('helper', lang)}</div>
        <div id="coverages-holder">{this._renderCoverages()}</div>
        <FooterButtons
          firstButtonText={getTranslatedText('footerButtons.back', lang)}
          secondButtonText={getTranslatedText('footerButtons.next', lang)}
          onClickFirstButton={this.onClickBackButton}
          onClickSecondButton={this.onClickNextButton}
        />
      </div>
    );
  }
}

Coverages.propTypes = {
  rdx: PropTypes.shape({}).isRequired,
  location: PropTypes.shape({}).isRequired,
  history: PropTypes.shape({}).isRequired,
};

export default withQuoteLayout(Connect(withRouter(Coverages)));
