import update from 'immutability-helper';
import { handleActions } from 'redux-actions';

const initialState = {
  hurricane: 2,
  otherPerils: 2500,
};

const coverages = handleActions(
  {
    SET_HURRICANE: (_state, { payload }) => update(_state, { hurricane: { $set: payload } }),
    SET_PERILS: (_state, { payload }) => update(_state, { otherPerils: { $set: payload } }),
  },
  initialState,
);

export default coverages;
