import update from 'immutability-helper';
import { handleActions } from 'redux-actions';

// Initial States

const contactFormIS = { email: '', phoneNumber: '' };

// Reducer state
const initialState = {
  contactForm: { ...contactFormIS },
};

// Reducer
const propertyAddress = handleActions(
  {
    VALUE_CHANGE_FQ: (_state, { payload }) =>
      update(_state, { [payload.field]: { [payload.key]: { $set: payload.value } } }),
    RESET: () => ({ ...initialState }),
  },
  initialState,
);

export default propertyAddress;
