import update from 'immutability-helper';
import { handleActions } from 'redux-actions';

// Initial States
const creditsIS = {
  lifestyleCredits: { name: 'Lifestyle Credits', cost: 100 },
  homeCredits: {
    name: 'Home Credits',
    credits: {
      smarterHomeCredits: {
        name: 'Smarter Home Credits',
        credits: {
          option1: { name: 'Option 1', cost: 100 },
          option2: { name: 'Option 2', cost: 100 },
          option3: { name: 'Option 3', cost: 100 },
        },
      },
      saferHomeCredits: { name: 'Safer Home Credits', cost: 100 },
      greenerHomeCredits: { name: 'Greener Home Credits', cost: 100 },
    },
  },
};

// Reducer state
const initialState = {
  credits: { ...creditsIS },
};

// Reducer
const credits = handleActions(
  {
    CREDIT_SELECT: (_state, { payload }) => {
      const { creditIndexArr, selectedState } = payload;
      const command = { selected: { $set: selectedState } };
      const target = creditIndexArr.reverse().reduce((acc, cur) => ({ [cur]: acc }), command);
      return update(_state, { credits: target });
    },
  },
  initialState,
);

export default credits;
