import { getTranslationFrom } from '../../utils/translate';

const texts = {
  en: {
    estimationText: 'My Estimated Instant Premium ',
  },
  es: {
    estimationText: 'Mi prima instantánea estimada ',
  },
};

export const getTranslatedText = getTranslationFrom(texts);

export default texts;
