import update from 'immutability-helper';
import { handleActions } from 'redux-actions';

// Initial States
const errorsIS = {
  message: 'error default message',
  isDisplayed: false,
  displayTimeOut: 2, // seconds
};

const navigationMapIS = {
  steps: [
    '1. Property Variables',
    '2. Initial Quote',
    '3. Coverages',
    '4. Additional Coverages',
    '5. Deductibles',
    '6. Credits',
    '7. Summary',
    '8. Finalize Quote',
  ],
  currentStep: 0,
};

const localizationIS = {
  lang: 'en',
};

// Reducer state
const initialState = {
  navigationMap: { ...navigationMapIS },
  errors: { ...errorsIS },
  localization: { ...localizationIS },
  videoSrc: 'https://www.youtube.com/embed/DJfgVaeQANU',
};

// Reducer
const global = handleActions(
  {
    APP_LANGUAGE_SET: (_state, { payload }) =>
      update(_state, { localization: { lang: { $set: payload } } }),
    NAVIGATION_MAP_STEP_SET: (_state, { payload }) =>
      update(_state, { navigationMap: { currentStep: { $set: payload } } }),
    ERROR_BANNER_MESSAGE_SET: (_state, { payload }) =>
      update(_state, {
        errors: { message: { $set: payload.message }, isDisplayed: { $set: payload.isDisplayed } },
      }),
  },
  initialState,
);

export default global;
