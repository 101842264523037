import React from 'react';
import PropTypes from 'prop-types';
// Styles
import styles from './styles.module.css';

const QuoteSummary = ({ text, overallValue }) => (
  <div className={styles.container}>
    <div className={styles['content-box']}>
      <div id="quote-text" className={`${styles['content-text']} ${styles['title-text']}`}>
        {text}
      </div>
      <div className={`${styles['content-text']} ${styles['value-text']}`}>${overallValue}</div>
    </div>
  </div>
);

QuoteSummary.propTypes = {
  text: PropTypes.string.isRequired,
  overallValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

export default QuoteSummary;
