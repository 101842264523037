import React, { Component } from 'react';
import PropTypes from 'prop-types';
import shortid from 'shortid';
import { withRouter } from 'react-router-dom';
// Components
import { PropertyCard, FooterButtons } from '../../components/index';
// HOCs
import withQuoteLayout from '../../components/withQuoteLayout';
// Utils
import { getTranslatedText } from './languages';
import Connect from '../../utils/connect';
// Styles
import styles from './styles.module.css';

class Coverages extends Component {
  state = {};

  componentDidMount() {
    const {
      rdx: {
        action: { setNavigationMapStep },
      },
    } = this.props;
    setNavigationMapStep(2);
  }

  onClickBackButton = () => {
    const {
      history,
      location: { search },
    } = this.props;
    const pathname = '/quote';
    history.replace({ pathname, search });
  };

  onClickNextButton = () => {
    const {
      history,
      location: { search },
    } = this.props;
    const pathname = 'additional-coverages';
    history.push({ pathname, search });
  };

  _renderCards = () => {
    const {
      rdx: {
        action: { selectCoverage },
        state: {
          global: {
            localization: { lang },
          },
          coverages: { coverages },
        },
      },
    } = this.props;

    return (
      <section className={styles['card-list']}>
        {coverages.map(({ name, description, selected }, coverageIdx) => (
          <PropertyCard
            key={shortid.generate()}
            property={name}
            selected={selected}
            description={description}
            onClick={() => selectCoverage({ arraySelector: 'coverages', index: coverageIdx })}
            onClickMore={() => selectCoverage(coverageIdx)}
            buttonName={getTranslatedText('cardButton.action', lang)}
            tabIndex={0}
          />
        ))}
      </section>
    );
  };

  render() {
    const {
      rdx: {
        state: {
          global: {
            localization: { lang },
          },
        },
      },
    } = this.props;

    return (
      <div className={styles.content}>
        <div id="helper-text" className={styles['helper-text']}>
          {getTranslatedText('helper', lang)}
        </div>
        {this._renderCards()}
        <FooterButtons
          firstButtonText={getTranslatedText('footerButtons.back', lang)}
          secondButtonText={getTranslatedText('footerButtons.next', lang)}
          onClickFirstButton={this.onClickBackButton}
          onClickSecondButton={this.onClickNextButton}
        />
      </div>
    );
  }
}

Coverages.propTypes = {
  rdx: PropTypes.shape({}).isRequired,
  location: PropTypes.shape({}).isRequired,
  history: PropTypes.shape({}).isRequired,
};

export default withQuoteLayout(Connect(withRouter(Coverages)));
