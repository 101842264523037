import React, { Fragment } from 'react';
import { Route } from 'react-router-dom';
import PropTypes from 'prop-types';
// Components
import ConfirmRateData from '../../../containers/ConfirmRateData';
// Layout HOCs
import withLayout from '../../withLayout';
import withNavigationMapLayout from '../../withNavigationMapLayout';
// Utils
import Connect from '../../../utils/connect';

// Decorated components
const DecoratedConfirmRouteData = withLayout(withNavigationMapLayout(ConfirmRateData));

const AppRoutes = props => {
  const { match } = props;
  return (
    <Fragment>
      <Route exact path={match.url} component={DecoratedConfirmRouteData} />
    </Fragment>
  );
};

AppRoutes.propTypes = {
  match: PropTypes.shape({}).isRequired,
};

export default Connect(AppRoutes);
