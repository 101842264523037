import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
// Components
import { QuoteSummary } from '..';
import { withRouter } from 'react-router-dom';
// Styles
import styles from './styles.module.css';
// Utils
import { getTranslatedText } from './languages';
import Connect from '../../utils/connect';

const withQuoteLayout = Component => {
  return withRouter(
    Connect(
      class QuoteLayout extends PureComponent {
        static propTypes = {
          match: PropTypes.shape({}).isRequired,
          rdx: PropTypes.shape({}).isRequired,
          history: PropTypes.shape({}).isRequired,
        };

        _findSelectedCreditsCosts = credit => {
          const { selected, credits, cost } = credit;

          const creditsList = Object.values(credits || {});

          if (creditsList && creditsList.length > 0) {
            return creditsList
              .map(_credit_ => this._findSelectedCreditsCosts(_credit_))
              .reduce((acc, cur) => acc + cur, 0);
          }

          return selected ? cost : 0;
        };

        getOverallValue = () => {
          const {
            rdx: {
              state: {
                coverages: { coverages, additionalCoverages },
                credits: { credits },
                initialQuote: { premium },
                deductibles: { hurricane, otherPerils },
              },
            },
          } = this.props;

          // Calculate coverages value
          const coveragesValue = coverages
            .filter(({ selected }) => selected)
            .reduce((acc, cur) => acc + cur.price, 0);
          // Calculate credits value
          const creditsList = Object.values(credits || {});
          const creditsValues = creditsList.map(credit => this._findSelectedCreditsCosts(credit));
          const sumCreditsValues = creditsValues.reduce((acc, cur) => acc + cur);
          // Additional coverages value
          const additionalValue = additionalCoverages
            .filter(({ selected }) => selected)
            .reduce((acc, cur) => acc + cur.price, 0);
          // Deductibles
          const deductibles = otherPerils / 10 - hurricane * 10;
          // Sum all values
          const overallValue =
            coveragesValue + sumCreditsValues + premium + additionalValue + deductibles;
          return overallValue;
        };

        render() {
          const {
            rdx: {
              state: {
                global: { localization },
                initialQuote: { address },
              },
            },
          } = this.props;

          const overallValue = this.getOverallValue();

          return (
            <div className={`${styles.container}`}>
              <span className={styles['address-section']}>{address}</span>
              <QuoteSummary
                text={getTranslatedText('estimationText', localization.lang)}
                overallValue={overallValue}
              />
              <Component />
            </div>
          );
        }
      },
    ),
  );
};

export default withQuoteLayout;
