import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
// Redux
import Connect from '../../utils/connect';
// Components
import { FooterButtons } from '../../components';
// Utils
import { getTranslatedText } from './languages';

// styles
import './styles.module.css';

class AdditionalRequirements extends Component {
  state = {};

  onHandleBackButtonClick = () => {
    const {
    history,
    location: { quote },
    } = this.props;
    const pathname = '/quote/insurer';
    history.replace({ pathname, quote });
};
    onHandleNextButtonClick = () => {
        const {
        history,
        location: { quote },
        } = this.props;
        const pathname = '/quote/finalize';
        history.push({ pathname, quote });
    };

  render() {
    const {
      rdx: {
        state: {
          global: { lang },
        },
      },
    } = this.props;
    return (
      <div className="confirmratedata-container text-center">
          <h1>Placeholder page for additional requirements</h1>
            <div className="buttons-wrapper">
              <FooterButtons
                firstButtonText={getTranslatedText('footerButtons.back', lang)}
                secondButtonText={getTranslatedText('footerButtons.next', lang)}
                onClickFirstButton={this.onHandleBackButtonClick}
                onClickSecondButton={this.onHandleNextButtonClick}
              />
            </div>
      </div>
    );
  }
}

AdditionalRequirements.propTypes = {
  rdx: PropTypes.shape({}).isRequired,
  history: PropTypes.shape({}).isRequired,
  location: PropTypes.shape({}).isRequired,
};

export default Connect(withRouter(AdditionalRequirements));
