export default {
  api: {
    typeahead: {
      host: process.env.REACT_APP_API_TYPEAHEAD_HOST || '',
      key: process.env.REACT_APP_API_TYPEAHEAD_KEY || '',
    },
    describe: {
      host: process.env.REACT_APP_API_DESCRIBE_HOST || '',
      key: process.env.REACT_APP_API_DESCRIBE_KEY || '',
    },
    scrub: {
      host: process.env.REACT_APP_API_SCRUB_HOST || '',
      key: process.env.REACT_APP_API_SCRUB_KEY || '',
    },
    rate: {
      host: process.env.REACT_APP_API_RATE_HOST || '',
      key: process.env.REACT_APP_API_RATE_KEY || '',
    },
  },
  google: {
    analytics: {
      id: process.env.REACT_APP_GOOGLE_ANALYTICS_ID || '',
    },
  },
};
