import React from 'react';
import { Spinner } from 'reactstrap';
import PropTypes from 'prop-types';
import { Input } from '../../../components';
import styles from './styles.module.css';

const SearchAddress = ({ searchValue, onChange, loading, options, onClickSuggestion }) => {
  return (
    <div id="sa-input" className={styles['search-wrapper']}>
      <div className={styles['sub-header']}>Start saving.</div>
      <div className={styles['row-wrapper']}>
        <Input
          className={`sa-input ${styles['sa-input']}`}
          // isValid={searchValue.length > 0}
          captionText={{ error: 'invalid entry', valid: 'valid entry' }}
          onChange={onChange}
          value={searchValue}
          options={options}
          onClickSuggestion={onClickSuggestion}
        />
        {loading && <Spinner type="grow" color="primary" />}
      </div>
    </div>
  );
};

SearchAddress.defaultProps = {
  options: null,
  onClickSuggestion: null,
};

SearchAddress.propTypes = {
  searchValue: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  options: PropTypes.instanceOf(Array),
  onClickSuggestion: PropTypes.func,
};

export default SearchAddress;
