import { combineReducers } from 'redux';
import app from './app';
import global from './global';
import coverages from './coverages';
import credits from './credits';
import deductibles from './deductibles';
import initialQuote from './initialQuote';
import insuranceInfo from './insuranceInfo';
import propertyAddress from './propertyAddress';
import finalizeQuote from './finalizeQuote';

export default combineReducers({
  app,
  global,
  coverages,
  credits,
  deductibles,
  initialQuote,
  insuranceInfo,
  propertyAddress,
  finalizeQuote,
});
