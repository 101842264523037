import React from 'react';
import PropTypes from 'prop-types';
import './styles.css';

const QuoteBox = ({ title, children }) => (
  <div className="q-box">
    <div className="title">{title}</div>
    <div className="q-content">{children}</div>
  </div>
);

QuoteBox.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.arrayOf(PropTypes.node).isRequired,
};

export default QuoteBox;
