import React from 'react';
import PropTypes from 'prop-types';
// Components
import { Button } from 'reactstrap';
// Styles
import styles from './styles.module.css';

const SelectableRow = ({ text, selected, onClick }) => {
  const containerClasses = ['selectable-row', styles.container];
  const buttonClasses = [styles['select-button']];

  if (selected) {
    buttonClasses.push('selected');
    buttonClasses.push(styles.selected);
    containerClasses.push(styles['container-selected']);
    containerClasses.push('selected');
  }

  return (
    <div className={containerClasses.join(' ')}>
      <div className={styles.text}>{text}</div>
      <Button className={buttonClasses.join(' ')} onClick={onClick} color="primary">
        Select
      </Button>
    </div>
  );
};

SelectableRow.defaultProps = {
  selected: false,
};

SelectableRow.propTypes = {
  text: PropTypes.string.isRequired,
  selected: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

export default SelectableRow;
