import Request from './request';
import config from '../config';

class Address extends Request {
  constructor() {
    super(config.api.typeahead.host, '');
    this._setHeader('x-api-key', config.api.typeahead.key);
  }

  typeAhead(address) {
    return this._get(`${address}`);
  }

  search(address) {
    return this._get('');
  }
}

const app = new Address();

export default app;
