import { getTranslationFrom } from '../../utils/translate';

const texts = {
  en: {
    helper: 'These will be additional coverages',
    'footerButtons.back': 'BACK',
    'footerButtons.next': 'NEXT',
  },
  es: {
    helper: 'Estas serán coberturas adicionales',
    'footerButtons.back': 'ATRAS',
    'footerButtons.next': 'SIGUIENTE',
  },
};

export const getTranslatedText = getTranslationFrom(texts);

export default texts;
