import Request from './request';
import config from '../config';

class Scrub extends Request {
  constructor() {
    super(config.api.scrub.host, 'scrub');
    this._setHeader('x-api-key', config.api.scrub.key);
  }

  scrub(parsedAddress) {
    const resultAddress = { 
      version: '1.0.0.0',
      provider: 'MELISSA',
      addressLine1: parsedAddress.address1,
      addressLine2: parsedAddress.address2,
      city: parsedAddress.city,
      state: parsedAddress.state,
      postalCode: parsedAddress.postalCode
    };

    return this._post('', resultAddress);
  }
}

const app = new Scrub();

export default app;
