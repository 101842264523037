import React, { Component } from 'react';
// Redux
import { Provider } from 'react-redux';
import { applyMiddleware, createStore, compose } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from './redux/reducers';
// Routes
import Routes from './components/Routes';

// bootstrap
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';

const middlewares = [];
middlewares.push(thunk);

const composeConfig = { trace: false };

const composeEnhancers =
  (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__(composeConfig)) ||
  compose;

const store = createStore(rootReducer, composeEnhancers(applyMiddleware(...middlewares)));

if (window.Cypress) {
  window.store = store;
}

class MainApp extends Component {
  state = {};

  render() {
    return (
      <Provider store={store}>
        <Routes />
      </Provider>
    );
  }
}

export default MainApp;
