import { createAction } from 'redux-actions';

export const setAppLanguage = createAction('APP_LANGUAGE_SET', (language = 'en') => language);

export const setBannerErrorMessage = createAction(
  'ERROR_BANNER_MESSAGE_SET',
  (message, isDisplayed = true) => ({
    message,
    isDisplayed,
  }),
);

export const setNavigationMapStep = createAction('NAVIGATION_MAP_STEP_SET', step => step);
