import Request from './request';
import config from '../config';

class Rating extends Request {
  constructor() {
    super(config.api.rate.host, '');
    this._setHeader('x-api-key', config.api.rate.key);
  }



  rateQuote(rateParameters) {
      
    return this._post('', rateParameters);
  }
}

const app = new Rating();

export default app;
