import React from 'react';
import PropTypes from 'prop-types';
// Components
import { Alert } from 'reactstrap';
// Styles
import styles from './styles.scss';

const ErrorBanner = ({ visible, message, toggle }) => {
  return (
    <div id="error-banner-wrapper" className={styles.container}>
      <Alert color="danger" isOpen={visible} toggle={toggle} className={styles.alert}>
        {message}
      </Alert>
    </div>
  );
};

ErrorBanner.propTypes = {
  visible: PropTypes.bool.isRequired,
  message: PropTypes.string.isRequired,
  toggle: PropTypes.func.isRequired,
};

export default ErrorBanner;
