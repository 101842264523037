import React from 'react';
import PropTypes from 'prop-types';
// Components
import { StyledButton } from '..';
// Styles
import './styles.css';

const FooterButtons = ({
  firstButtonText,
  secondButtonText,
  onClickFirstButton,
  onClickSecondButton,
}) => {
  return (
    <div className="buttons-wrapper">
      <StyledButton
        id="first-footer-btn"
        text={firstButtonText}
        onClick={onClickFirstButton}
        classType="clear"
      />
      <StyledButton id="second-footer-btn" text={secondButtonText} onClick={onClickSecondButton} />
    </div>
  );
};

FooterButtons.propTypes = {
  firstButtonText: PropTypes.string.isRequired,
  secondButtonText: PropTypes.string.isRequired,
  onClickFirstButton: PropTypes.func.isRequired,
  onClickSecondButton: PropTypes.func.isRequired,
};

export default FooterButtons;
