import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
// Components
import { ItemBox, FooterButtons } from '../../components';
// HOCs
import withQuoteLayout from '../../components/withQuoteLayout';
// Utils
import Connect from '../../utils/connect';
import { getTranslatedText } from './languages';
// Styles
import styles from './styles.module.css';
import 'rc-slider/assets/index.css';

class Summary extends Component {
  state = {
    pageCoverages: 1,
    pageCredits: 1,
    pageDeductibles: 1,
  };

  componentDidMount() {
    const {
      rdx: {
        action: { setNavigationMapStep },
      },
    } = this.props;
    setNavigationMapStep(6);
  }

  nextHandler = (page, curPage) => {
    this.setState({ [page]: curPage + 1 });
  };

  previousHandler = (page, curPage) => {
    this.setState({ [page]: curPage - 1 });
  };

  clickNumber = (page, numberOfPage) => {
    this.setState({ [page]: numberOfPage });
  };

  onClickBackButton = () => {
    const {
      history,
      location: { search },
    } = this.props;
    const pathname = '/quote/credits';
    history.replace({ pathname, search });
  };

  onClickNextButton = () => {
    const {
      history,
      location: { search },
    } = this.props;
    const pathname = 'finalize';
    history.push({ pathname, search });
  };

  _findCreditsActives = credit => {
    const { selected, credits, name } = credit;
    const creditsList = Object.values(credits || {});
    if (creditsList && creditsList.length > 0) {
      return creditsList
        .map(_credit_ => this._findCreditsActives(_credit_))
        .filter(creditx => creditx)
        .flat();
    }
    if (selected) {
      return name;
    }
    return null;
  };

  _fillList = () => {
    const {
      rdx: {
        state: {
          coverages: { coverages, additionalCoverages },
          deductibles,
          credits: { credits },
        },
      },
    } = this.props;

    const coveragesItems = [];
    coverages.forEach(coverage => {
      if (coverage.selected) coveragesItems.push(coverage.name);
    });
    additionalCoverages.forEach(coverage => {
      if (coverage.selected) coveragesItems.push(coverage.name);
    });
    const deductiblesItems = [];
    Object.keys(deductibles).forEach(key => deductiblesItems.push(`${key}: ${deductibles[key]}`));
    // const creditsList = Object.values(credits || {});
    const creditsItems = Object.keys(credits)
      .map(key => this._findCreditsActives(credits[key]))
      .filter(creditx => creditx)
      .flat();
    return { coveragesItems, deductiblesItems, creditsItems };
  };

  render() {
    const {
      rdx: {
        state: {
          global: {
            localization: { lang },
          },
        },
      },
    } = this.props;
    const { coveragesItems, deductiblesItems, creditsItems } = window.Cypress
      ? { coveragesItems: [], deductiblesItems: [], creditsItems: [] }
      : this._fillList();
    const { pageDeductibles, pageCoverages, pageCredits } = this.state;

    return (
      <div className={styles.content}>
        <div className={styles['summary-content']}>
          <div className={styles.holder}>
            <div className={styles.title}>{getTranslatedText('itemBox.coverages.title', lang)}</div>
            <ItemBox
              items={coveragesItems}
              page={pageCoverages}
              maxItems={10}
              nextHandler={() => this.nextHandler('pageCoverages', pageCoverages)}
              previousHandler={() => this.previousHandler('pageCoverages', pageCoverages)}
              clickNumber={number => this.clickNumber('pageCoverages', number)}
            />
          </div>
          <div className={styles.holder}>
            <div className={styles.title}>
              {getTranslatedText('itemBox.deductibles.title', lang)}
            </div>
            <ItemBox
              items={deductiblesItems}
              page={pageDeductibles}
              maxItems={10}
              nextHandler={() => this.nextHandler('pageDeductibles', pageDeductibles)}
              previousHandler={() => this.previousHandler('pageDeductibles', pageDeductibles)}
              clickNumber={number => this.clickNumber('pageDeductibles', number)}
            />
          </div>
          <div className={styles.holder}>
            <div className={styles.title}>{getTranslatedText('itemBox.credits.title', lang)}</div>
            <ItemBox
              items={creditsItems}
              page={pageCredits}
              maxItems={10}
              nextHandler={() => this.nextHandler('pageCredits', pageCredits)}
              previousHandler={() => this.previousHandler('pageCredits', pageCredits)}
              clickNumber={number => this.clickNumber('pageCredits', number)}
            />
          </div>
        </div>wwww
        <FooterButtons
          firstButtonText={getTranslatedText('footerButtons.back', lang)}
          secondButtonText={getTranslatedText('footerButtons.finalize', lang)}
          onClickFirstButton={this.onClickBackButton}
          onClickSecondButton={this.onClickNextButton}
        />
      </div>
    );
  }
}

Summary.propTypes = {
  rdx: PropTypes.shape({}).isRequired,
  history: PropTypes.shape({}).isRequired,
  location: PropTypes.shape({}).isRequired,
};

export default withQuoteLayout(Connect(withRouter(Summary)));
