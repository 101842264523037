import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
// Components
import { FooterButtons } from '../../components';
// HOCs
import withQuoteLayout from '../../components/withQuoteLayout';
import withFinalizeQuoteLayout from '../../components/withFinalizeQuoteLayout';
// Utils
import { getTranslatedText } from './languages';
import Connect from '../../utils/connect';
// Styles
import styles from './styles.module.css';

class FinalizeQuote extends Component {
  state = {};

  componentDidMount() {
    const {
      rdx: {
        action: { setNavigationMapStep },
      },
    } = this.props;

    setNavigationMapStep(7);
  }

  onClickBackButton = () => {
    const {
      history,
    } = this.props;
    history.goBack();
  };

  onClickNextButton = () => {
    this.submitForm();
  };

  onInputChange = ({ target }) => {
    const {
      rdx: {
        action: { changeFQValue },
      },
    } = this.props;

    const { name, value } = target;

    changeFQValue('contactForm', name, value);
  };

  submitForm = () => {
    const {
      rdx: {
        action: { submitContactForm, resetFQ },
        state: {
          finalizeQuote: { contactForm },
        },
      },
    } = this.props;

    submitContactForm(contactForm);
    resetFQ();
  };

  render() {
    const {
      rdx: {
        state: {
          global: { localization }
        },
      },
    } = this.props;

    return (
      <div className={styles.content}>
        <FooterButtons
          firstButtonText={getTranslatedText('footerbuttons.back', localization.lang)}
          secondButtonText={getTranslatedText('footerbuttons.send', localization.lang)}
          onClickFirstButton={this.onClickBackButton}
          onClickSecondButton={this.onClickNextButton}
        />
      </div>
    );
  }
}

FinalizeQuote.propTypes = {
  rdx: PropTypes.shape({}).isRequired,
  match: PropTypes.shape({}).isRequired,
  history: PropTypes.shape({}).isRequired,
  location: PropTypes.shape({}).isRequired,
};

export default withFinalizeQuoteLayout(withQuoteLayout(Connect(withRouter(FinalizeQuote))));
