import { getTranslationFrom } from '../../utils/translate';

const texts = {
  en: {
    phrase: 'You just made a great choice!',
  },
  es: {
    phrase: 'Ha hecho una gran eleccion!',
  },
};

export const getTranslatedText = getTranslationFrom(texts);

export default texts;
