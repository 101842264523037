import React from 'react';
import PropTypes from 'prop-types';
import querystring from 'query-string';
// Components
import { Navbar, ErrorBanner, Footer } from '..';
// Utils
import { getTranslatedText } from './languages';
import Connect from '../../utils/connect';
// Styles
import './styles.scss';

const withLayout = Component =>
  Connect(
    class Layout extends React.Component {
      static propTypes = {
        rdx: PropTypes.shape({}).isRequired,
        history: PropTypes.shape({}).isRequired,
        location: PropTypes.shape({}).isRequired,
      };

      state = {};

      componentDidMount() {
        const {
          rdx: {
            action: { setBannerErrorMessage },
          },
        } = this.props;

        if (!navigator.onLine) {
          setBannerErrorMessage('Not connected to the internet');
        }
      }

      componentDidUpdate() {
        const {
          location: { search },
          rdx: {
            action: { setAppLanguage },
            state: {
              global: {
                localization: { lang },
              },
            },
          },
        } = this.props;

        const queryString = querystring.parse(search);

        if (queryString.lang !== lang) {
          setAppLanguage(queryString.lang);
        }
      }

      changeLanguageHandler = language => {
        const {
          history,
          location: { pathname, search },
        } = this.props;
        const parsedQueryString = querystring.parse(search);
        const newQueryString = { ...parsedQueryString, lang: language };
        const searchString = querystring.stringify(newQueryString);
        history.push({ pathname, search: searchString });
      };

      render() {
        const {
          rdx: {
            action: { setBannerErrorMessage },
            state: {
              global: {
                errors,
                localization: { lang },
              },
            },
          },
        } = this.props;

        return (
          <div className="layout">
            <Navbar />
            <ErrorBanner
              visible={errors.isDisplayed}
              message={errors.message}
              toggle={() => setBannerErrorMessage('', !errors.isDisplayed)}
            />
            <div className="layout-content">
              <Component />
            </div>
            <Footer
              language={lang}
              getTranslatedText={getTranslatedText}
              changeLanguage={this.changeLanguageHandler}
            />
          </div>
        );
      }
    },
  );

export default withLayout;
