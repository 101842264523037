import React, { Component } from 'react';
import PropTypes from 'prop-types';
import shortid from 'shortid';

// Redux
import Connect from '../../utils/connect';
// styles

class HelpPage extends Component {
  state = {};

  renderInfo = messages =>
    messages.map(message => {
      return (
        <div key={shortid.generate()}>
          <h2>{message.title}</h2>
          <h5>{message.text}</h5>
        </div>
      );
    });

  render() {
    const {
      rdx: {
        state: {
          insuranceInfo: { messages },
        },
      },
    } = this.props;
    return <div>{this.renderInfo(messages)}</div>;
  }
}

HelpPage.propTypes = {
  rdx: PropTypes.shape({}).isRequired,
};

export default Connect(HelpPage);
