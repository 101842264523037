import { getTranslationFrom } from '../../utils/translate';

const texts = {
  en: {
    helper: 'Save more! Choose your credits',
    'footerButtons.back': 'BACK',
    'footerButtons.next': 'NEXT',
  },
  es: {
    helper: '¡Guardar mas! Elija sus créditos',
    'footerButtons.back': 'ATRAS',
    'footerButtons.next': 'SIGUIENTE',
  },
};

export const getTranslatedText = getTranslationFrom(texts);

export default texts;
