import { handleActions } from 'redux-actions';

const initialState = {
  title: 'What Is Homeowners Insurance And What Does It Cover?',
  text: `Homeowners insurance is made up of coverages that may help pay to repair or replace your home and belongings if they are damaged by certain perils, such as fire or theft. It may also help cover costs if you accidentally damage another person's property or if a visitor is injured at your home.`,
};

const app = handleActions(
  {
    VALUE_CHANGE: (_state, { payload }) => ({ ..._state, ...payload }),
    VALUE_CHANGE_ASYNC_SUCCEEDED: (_state, { payload }) => ({ ..._state, ...payload }),
  },
  initialState,
);

export default app;
