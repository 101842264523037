import { createAction } from 'redux-actions';
import { createActionThunk } from 'redux-thunk-actions';
import { Scrub, Describe, Rating } from '../../api';
import { parseAddress } from '../../utils/address';

export const setAddressQuote = createAction('SET_ADDRESS', address => {
  return address;
});

export const setRateParametersQuote = createAction('SET_RATE_PARAMETERS', rateParameters => {
  return rateParameters;
});

export const changeQValue = createAction('VALUE_CHANGE', (field, key, value) => ({
  field,
  key,
  value,
}));

export const scrubAddress = createActionThunk('SCRUB_ADDRESS', async address => {
  const parsedAddress = parseAddress(address);
  const response = await Scrub.scrub(parsedAddress);
  const result = response.data.message.response;
  return result;
});

export const describeAddress = createActionThunk('DESCRIBE_ADDRESS', async address => {
  const parsedAddress = parseAddress(address);
  const response = await Describe.describe(parsedAddress);
  const result = response.data.message.response;
  return result;
});

export const rateQuote = createActionThunk('RATE_PROPERTY', async rateParameters => {
  const response = await Rating.rateQuote(rateParameters);
  const result = response.data.message.ratingResult;
  return parseInt(result.finalPremium.finalPremium, 10);
});
