import React, { Component } from 'react';
import PropTypes from 'prop-types';
import shortid from 'shortid';

import styles from './styles.module.css';

class ItemBox extends Component {
  _renderPages = maxValue => {
    const pages = [];

    const { page, clickNumber } = this.props;

    let pageStyle = [styles.page];

    for (let i = 0; i <= maxValue; i += 1) {
      if (page === i + 1) {
        pageStyle.push(styles['current-page']);
      } else {
        pageStyle = [styles.page];
      }
      pages.push(
        <button
          className={pageStyle.join(' ')}
          key={shortid.generate()}
          onClick={() => clickNumber(i + 1)}
          type="button"
        >
          {i + 1}
        </button>,
      );
    }
    return pages;
  };

  _renderFooter = numberOfPages => {
    const { nextHandler, previousHandler, page } = this.props;
    if (numberOfPages <= 1)
      return (
        <div className={styles.footer}>
          <div className={styleMedia['footer-empty']} />
        </div>
      );
    return (
      <div className={styles.pagination}>
        <button
          className={styles['pagination-item']}
          type="button"
          onClick={previousHandler}
          disabled={page <= 1}
        >
          &lt;
        </button>
        <div className={styles.pages}>{this._renderPages(numberOfPages)}</div>
        <button
          className={styles['pagination-item']}
          onClick={nextHandler}
          type="button"
          disabled={page > numberOfPages}
        >
          &gt;
        </button>
      </div>
    );
  };

  render() {
    const { items, page, maxItems } = this.props;

    const start = (page - 1) * maxItems;
    const end = start + maxItems;
    const itemsPage = items.slice(start, end);
    return (
      <div className={styles.container}>
        <div>
          {itemsPage.map(item => (
            <div className={styles.item} key={shortid.generate()}>
              <div className={styles['item-dot']}>∙</div>
              {item}
            </div>
          ))}
        </div>
        {this._renderFooter(items.length / maxItems)}
      </div>
    );
  }
}

ItemBox.propTypes = {
  page: PropTypes.number.isRequired,
  maxItems: PropTypes.number.isRequired,
  nextHandler: PropTypes.func.isRequired,
  previousHandler: PropTypes.func.isRequired,
  clickNumber: PropTypes.func.isRequired,
  items: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default ItemBox;
