import React from 'react';
import PropTypes from 'prop-types';
// Components
import { Button } from 'reactstrap';
// Styles
import './styles.css';

const StyledButton = ({ classType, text, color, onClick, ...rest }) => {
  return (
    <div className="button">
      <Button className={classType} color={color} onClick={onClick} {...rest}>
        {text}
      </Button>
    </div>
  );
};

StyledButton.defaultProps = {
  classType: '',
  color: 'primary',
};

StyledButton.propTypes = {
  classType: PropTypes.string,
  text: PropTypes.string.isRequired,
  color: PropTypes.string,
  onClick: PropTypes.func.isRequired,
};

export default StyledButton;
