import React from 'react';
import { Router, Switch, Route } from 'react-router-dom';
import { createBrowserHistory } from 'history';
// Utils
import ReactGA from '../../utils/googleAnalytics';
// Components
import Home from '../../containers/Home';
import PropertyAddress from '../../containers/PropertyAddress';
// Routes
import QuoteRoutes from './QuoteRoutes';
import AppRoutes from './AppRoutes';
import HelpPage from '../../containers/HelpPage';
// Layout HOC
import withLayout from '../withLayout';

const history = createBrowserHistory();
// Decorated components

history.listen(location => {
  ReactGA.pageview(location.pathname);
});

const NoMatch = () => <div>404</div>;

const Routes = () => (
  <Router history={history}>
    <Switch>
      <Route exact path="/" component={withLayout(Home)} />
      <Route exact path="/search" component={withLayout(PropertyAddress)} />
      <Route path="/quote" component={QuoteRoutes} />
      <Route path="/app" component={AppRoutes} />
      <Route exact path="/help" component={withLayout(HelpPage)} />
      <Route path="*" component={NoMatch} />
    </Switch>
  </Router>
);

export default Routes;
