import React from 'react';
import PropTypes from 'prop-types';
import shortid from 'shortid';
import './styles.css';

const NavigationMap = ({ currentStep, steps }) => {
  const barWidth = (1 + currentStep) / steps.length;

  return (
    <div className="nav-map-container">
      <span className="bar" style={{ width: `${100 * barWidth}%` }} />
      <span key="barBG" className="barBG" style={{ width: `${100 * (1-barWidth)}%` }} />
      <div className="steps-container">
        {steps.map((step, idx) => {
          const stepClasses = ['step'];

          if (currentStep === idx) {
            stepClasses.push('highlight');
          }

          if (currentStep < idx) {
            stepClasses.push('hidden');
          }

          return (
            <div className={stepClasses.join(' ')} key={shortid.generate()}>
              {step}
            </div>
          );
        })}
      </div>
    </div>
  );
};

NavigationMap.defaultProps = {
  steps: [],
  currentStep: 0,
};

NavigationMap.propTypes = {
  steps: PropTypes.instanceOf(Array),
  currentStep: PropTypes.number,
};

export default NavigationMap;
