import update from 'immutability-helper';
import { handleActions } from 'redux-actions';

// Initial States
const searchIS = {
  value: '',
  suggestions: [],
};

const loadingScreenIS = {
  messages: [],
};

const searchedIS = window.Cypress !== undefined;

// Reducer state
const initialState = {
  search: { ...searchIS },
  searched: searchedIS,
  loading: false,
  loadingScreen: { ...loadingScreenIS },
};

// Reducer
const propertyAddress = handleActions(
  {
    VALUE_CHANGE: (_state, { payload }) =>
      update(_state, { [payload.field]: { [payload.key]: { $set: payload.value } } }),
    ADDRESS_SEARCH_STARTED: _state =>
      update(_state, { loading: { $set: true }, searched: { $set: false } }),
    ADDRESS_SEARCH_SUCCEEDED: (_state, { payload }) =>
      update(_state, { fullAddress: { $set: payload }, searched: { $set: true } }),
    ADDRESS_SEARCH_ENDED: _state => update(_state, {}),
    LOADING_SCREEN_MESSAGE_ADD: (_state, { payload }) =>
      update(_state, { loadingScreen: { messages: { $push: [payload] } } }),
    LOADING_SCREEN_SET: (_state, { payload }) =>
      update(_state, {
        loading: { $set: payload },
        loadingScreen: { messages: { $set: loadingScreenIS.messages } },
      }),
    TYPEAHEAD_FETCH_SUCCEEDED: (_state, { payload }) =>
      update(_state, {
        search: { suggestions: { $set: payload } },
      }),
    SEARCHED_FLAG_SET: (_state, { payload }) => update(_state, { searched: { $set: payload } }),
  },
  initialState,
);

export default propertyAddress;
