import React from 'react';
import propTypes from 'prop-types';
// Components
import { withRouter } from 'react-router-dom';
// Styles
import './styles.scss';

const Footer = ({ language, getTranslatedText, changeLanguage }) => {
  return (
    <footer className="footer-container">
      <div className="content-wrapper">
        <div />
        <div className="app">OPEN HOUSE</div>
        <ul className="languages-list">
          {getTranslatedText('languageOptions', language)}
          <li role="presentation" onClick={() => changeLanguage('en')}>
            {getTranslatedText('languageOptions.english', language)}
          </li>
          <li role="presentation" onClick={() => changeLanguage('es')}>
            {getTranslatedText('languageOptions.spanish', language)}
          </li>
        </ul>
      </div>
    </footer>
  );
};

Footer.defaultProps = {
  language: 'en',
};

Footer.propTypes = {
  language: propTypes.string,
  getTranslatedText: propTypes.func.isRequired,
  changeLanguage: propTypes.func.isRequired,
};

export default withRouter(Footer);
