import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
// Utils
import { getTranslatedText } from './languages';
import Connect from '../../utils/connect';
// Styles
import styles from './styles.module.css';

const withFinalizeQuoteLayout = Component =>
  Connect(
    class NavigationMapLayout extends PureComponent {
      static propTypes = {
        rdx: PropTypes.shape({}).isRequired,
      };

      state = {};

      render() {
        const {
          rdx: {
            state: {
              global: { localization },
            },
          },
        } = this.props;

        return (
          <div className={`${styles.container}`}>
            <span id="finalize-phrase" className={styles.comment}>
              {getTranslatedText('phrase', localization.lang)}
            </span>
            <Component />
          </div>
        );
      }
    },
  );

export default withFinalizeQuoteLayout;
