import { getTranslationFrom } from '../../utils/translate';

const texts = {
  en: {
    'footerButtons.next': 'Next',
    'footerButtons.create': 'Create Policy',
    'quoteCard.coverages.title': 'my coverages',
    'quoteCard.deductibles.title': 'my deductibles',
    'quoteCard.credits.title': 'my credits',
  },
  es: {
    'footerButtons.customize': 'Personalizar',
    'footerButtons.create': 'Crear Poliza',
    'quoteCard.coverages.title': 'mis coberturas',
    'quoteCard.deductibles.title': 'mis deducibles',
    'quoteCard.credits.title': 'mis creditos',
  },
};

export const getTranslatedText = getTranslationFrom(texts);

export default texts;
