import { createAction } from 'redux-actions';
import { createActionThunk } from 'redux-thunk-actions';
import { sleep } from '../../utils/timeout';
import { AppV1 } from '../../api';

export const changeValue = createAction('VALUE_CHANGE', (key, value) => ({ [key]: value }));
export const changeValueAsync = createActionThunk('VALUE_CHANGE_ASYNC', async (key, value) => {
  await sleep(2);
  return { [key]: value };
});

export const get = createActionThunk('GET', async () => {
  const result = await AppV1.get();
  return result.data;
});
