import { createAction } from 'redux-actions';
import { createActionThunk } from 'redux-thunk-actions';
import { Address, Scrub } from '../../api';
import { parseAddress } from '../../utils/address';

export const changePAValue = createAction('VALUE_CHANGE', (field, key, value) => ({
  field,
  key,
  value,
}));

export const addLoadingScreenMessage = createAction('LOADING_SCREEN_MESSAGE_ADD');

export const searchAddress = createActionThunk('ADDRESS_SEARCH', async typedAddress => {
  const parsedAddress = parseAddress(typedAddress);
  const result = await Scrub.scrub(parsedAddress);
  const resultData = result.data.message.response;
  return resultData;
});

export const addressTypeahead = createActionThunk('TYPEAHEAD_FETCH', async typedAddress => {
  if (typedAddress.length <= 3) {
    return [];
  }
  
  const result = await Address.typeAhead(typedAddress);
  const resultData = result.data.message.response.results;
  const suggestions = resultData.map(suggestion => `${ suggestion.address.addressLine1} ${suggestion.address.city }, ${suggestion.address.state} ${suggestion.address.postalCode}`);
  return suggestions;
});

export const displayLoadingScreen = createAction('LOADING_SCREEN_SET', (state = true) => state);

export const setSearchedFlag = createAction('SEARCHED_FLAG_SET', flagState => {
  return flagState;
});
