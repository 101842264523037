import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
// Components
import { FooterButtons, SelectableRowMenu } from '../../components';
// HOCs
import withQuoteLayout from '../../components/withQuoteLayout';
// Utils
import { getTranslatedText } from './languages';
import Connect from '../../utils/connect';
// Styles
import styles from './styles.module.css';

class Credits extends Component {
  state = {};

  componentDidMount() {
    const {
      rdx: {
        action: { setNavigationMapStep },
      },
    } = this.props;

    setNavigationMapStep(5);
  }

  onClickBackButton = () => {
    const {
      history,
      location: { search },
    } = this.props;
    const pathname = 'deductibles';
    history.replace({ pathname, search });
  };

  onClickNextButton = () => {
    const {
      history,
      location: { search },
    } = this.props;
    const pathname = 'summary';
    history.push({ pathname, search });
  };

  render() {
    const {
      rdx: {
        action: { selectCredit },
        state: {
          global: {
            localization: { lang },
          },
          credits: { credits },
        },
      },
    } = this.props;

    return (
      <div className={styles.content}>
        <div id="helper-text" className={styles['helper-text']}>
          {getTranslatedText('helper', lang)}
        </div>
        <SelectableRowMenu data={credits} listKeyProperty="credits" onClickAction={selectCredit} />
        <FooterButtons
          firstButtonText={getTranslatedText('footerButtons.back', lang)}
          secondButtonText={getTranslatedText('footerButtons.next', lang)}
          onClickFirstButton={this.onClickBackButton}
          onClickSecondButton={this.onClickNextButton}
        />
      </div>
    );
  }
}

Credits.propTypes = {
  rdx: PropTypes.shape({}).isRequired,
  location: PropTypes.shape({}).isRequired,
  match: PropTypes.shape({}).isRequired,
  history: PropTypes.shape({}).isRequired,
};

export default withQuoteLayout(Connect(withRouter(Credits)));
