import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import shortid from 'shortid';
// Component
import { SelectableRow } from '..';

class SelectableRowMenu extends PureComponent {
  state = {};

  _renderRecursive = (object, pathArray) => {
    const { listKeyProperty, onClickAction } = this.props;
    const { name, selected, [listKeyProperty]: objectSubList } = object;
    const objectList = Object.values(objectSubList || {});
    const objectKeys = Object.keys(objectSubList || {});

    // Render sublist
    if (objectList && objectList.length > 0) {
      return (
        <Fragment>
          <SelectableRow
            text={name}
            selected={selected}
            onClick={() => onClickAction(pathArray, !selected)}
          />
          {selected &&
            objectList.map((_object_, idx) => {
              const newPath = [...pathArray, listKeyProperty, objectKeys[idx]];
              return (
                <div key={shortid.generate()} style={{ marginLeft: '105px' }}>
                  {this._renderRecursive(_object_, newPath)}
                </div>
              );
            })}
        </Fragment>
      );
    }

    // Render row
    return (
      <Fragment>
        <SelectableRow
          text={name}
          selected={selected}
          onClick={() => onClickAction(pathArray, !selected)}
        />
      </Fragment>
    );
  };

  render() {
    const { data } = this.props;
    const dataValues = Object.values(data || {});
    const dataKeys = Object.keys(data || {});

    return dataValues.map((object, idx) => (
      <Fragment key={shortid.generate()}>{this._renderRecursive(object, [dataKeys[idx]])}</Fragment>
    ));
  }
}

SelectableRowMenu.propTypes = {
  data: PropTypes.shape({}).isRequired,
  listKeyProperty: PropTypes.string.isRequired,
  onClickAction: PropTypes.func.isRequired,
};

export default SelectableRowMenu;
