import { getTranslationFrom } from '../../utils/translate';

const texts = {
  en: {
    'footerButtons.back': 'BACK',
    'footerButtons.finalize': 'FINALIZE',
    'itemBox.coverages.title': 'My Coverages',
    'itemBox.deductibles.title': 'My Deductibles',
    'itemBox.credits.title': 'My Credits',
  },
  es: {
    'footerButtons.back': 'VOLVER',
    'footerButtons.finalize': 'FINALIZAR',
    'itemBox.coverages.title': 'Mis Coberturas',
    'itemBox.deductibles.title': 'Mis Deducibles',
    'itemBox.credits.title': 'Mis Creditos',
  },
};

export const getTranslatedText = getTranslationFrom(texts);

export default texts;
