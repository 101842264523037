import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
// Components
import { withRouter } from 'react-router-dom';
import { NavigationMap } from '..';
// Styles
import styles from './styles.module.css';
// Utils
import Connect from '../../utils/connect';

const withNavigationMapLayout = Component => {
  return withRouter(
    Connect(
      class NavigationMapLayout extends PureComponent {
        static propTypes = {
          rdx: PropTypes.shape({}).isRequired,
        };

        render() {
          const {
            rdx: {
              state: {
                global: {
                  navigationMap: { currentStep, steps },
                },
              },
            },
          } = this.props;

          return (
            <div className={`${styles.container}`}>
              <NavigationMap currentStep={currentStep} steps={steps} />
              <div className={styles['content-wrapper']}>
                <Component />
              </div>
            </div>
          );
        }
      },
    ),
  );
};

export default withNavigationMapLayout;
