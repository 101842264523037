import update from 'immutability-helper';
import { handleActions } from 'redux-actions';

const rateParameters = {
  policyNumber: "0142059068",
  policyTermDate: "01/01/2019",
  ratingEffectiveDate: "03/06/2019",

  key: {
    formTypeId: 3,
    entity: "00000000-0000-0000-0000-000000000000",
    stateId: 12
  },
  ratingInformation: {
    hoForm: "HO3",
    territory: "541",
    protectionClass: 4,
    constructionTypeId: 1,
    bceg: 3,
    yearBuilt: 1999,
    usage: "Owner Primary",
    aopDeductible: 5000,
    hurricaneDeductible: 0.05,
    protectiveDevices: "1,2",
    cva: 750000,
    cvb: -1,
    cvc: -1,
    cvd: -1,
    cve: 300000,
    cvf: 10000,
    insuranceScore: 777,
    priorClaims: 2,
    WINDLossMitigationInformation: {
      terrainExposureTypeId: 2,
      roofShapeTypeId: 2,
      hasSWR: true,
      isFBC: false,
      fbcInformation: {
        roofDeckTypeId: 0,
        internalPressureDesignTypeId: 0,
        isWindBorneDebrisRegion: true,
        hasOpeningProtection: true,
        fbcWindSpeed: 120,
        windSpeedOfDesign: 120
      },
      nonFbcInformation: {
        roofCoverTypeId: 2,
        roofDeckAttachmentTypeId: 1,
        roofWallConnectionTypeId: 1,
        openingProtectionTypeId: 2
      }
    }
  },

  endorsements: {
    additionalResidenceRentedToOthers: {
      hasOption: true,
      occupancyTypeId: 1
    },
    animalLiability: {
      hasOption: false,
      limit: 0
    },
    businessPursuits: {
      hasOption: false,
      businessPursuitTypeId: 0,
      liabilityLimit: 0,
      medicalPaymentsLimit: 0
    },
    fraudProtection: {
      hasOption: true,
      limit: 10000
    },
    inflationGuard: {
      hasOption: true,
      annualIncrease: 0.04
    },
    moldAndRot: {
      hasOption: true,
      limit: 50000
    },
    lossAssessmentCoverage: {
      hasOption: true,
      limit: 2000
    },
    lossOfUseCoverage: {
      hasOption: true,
      coverageAPct: 0.2
    },
    ordinanceOrLawIncrease: {
      hasOption: false
    },
    otherStructuresIncreasedLimit: {
      hasOption: false,
      increasedCoverageVal: 0
    },
    otherStructuresDecreasedLimit: {
      hasOption: true,
      cvbPct: 0.1
    },
    otherStructuresRented: {
      hasOption: false,
      limit: 0
    },
    permittedIncidentalOccupancies: {
      hasOption: false,
      limit: 0
    },
    personalInjury: {
      hasOption: false
    },
    personalLiabilityIncreasedLimit: {
      hasOption: true
    },
    personalPropertyIncreaseReduction: {
      hasOption: true
    },
    personalPropertyReplacementCost: {
      hasOption: true
    },
    scheduledPersonalPropertyPremium: {
      hasOption: false,
      antiquesValue: 0,
      cameraValue: 0,
      coinsValue: 0,
      fineArtsValue: 0,
      hasFineArtsRreakage: false,
      fursValue: 0,
      golfEquipValue: 0,
      gunsValue: 0,
      hasGunsCollectible: false,
      jewelryValue: 0,
      miscPersonalPropertyValue: 0,
      musicInstrumentValue: 0,
      otherSportsEquipValue: 0,
      silverwareValue: 0,
      stampsValue: 0
    },
    screenEnclosureCoverage: {
      hasOption: true,
      limit: 5000,
      inCoastalCounty: false
    },
    sinkholeLossCoverage: {
      hasOption: false
    },
    waterBackupCoverage: {
      hasOption: true
    },
    watercraftLiabilityCoverage: {
      hasOption: false,
      isSailboat: false,
      horsePower: 0,
      length: 0
    }
  },

  underwritingSurcharges: {
    seasonalOccupancy:{
      hasOption: false
    }
  }
};

const initialState = {
  address: '',
  email: '',
  coverages: {},
  deductibles: {},
  credits: {},
  scrub: {},
  addressDescribe: {},
  rateParameters: { ...rateParameters},
  premium: 0,
};

const propertyAddress = handleActions(
  {
    SET_ADDRESS: (_state, { payload }) => update(_state, { address: { $set: payload } }),
    SCRUB_ADDRESS_SUCCEEDED: (_state, { payload }) =>
      update(_state, {
        scrub: { $set: payload },
      }),
    DESCRIBE_ADDRESS_SUCCEEDED: (_state, { payload }) =>
      update(_state, {
        addressDescribe: { $set: payload },
      }),
    RATE_PROPERTY_SUCCEEDED: (_state, { payload }) =>
      update(_state, {
        premium: { $set: payload },
      }),
  },
  initialState,
);

export default propertyAddress;
