import { getTranslationFrom } from '../../utils/translate';

const texts = {
  en: {
    'inputs.email': 'Email address',
    'inputs.phonenumber': 'Phone number',
    'footer.terms': 'By clicking Send you accept ',
    terms: 'Terms of Use.',
    'footerbuttons.back': 'Back',
    'footerbuttons.send': 'Apply For Policy',
  },
  es: {
    'inputs.email': 'Direccion de correo',
    'inputs.phonenumber': 'Numero telefonico',
    'footer.terms': 'Al realizar click en enviar usted acepta ',
    terms: 'Terminos de Uso.',
    'footerbuttons.back': 'Atras',
    'footerbuttons.send': 'Solicitar la Poliza',
  },
};

export const getTranslatedText = getTranslationFrom(texts);

export default texts;
