import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
// Redux
import Connect from '../../utils/connect';
// Components
import { StyledButton } from '../../components';
// Utils
import { getTranslatedText } from './languages';

// styles
import './styles.module.css';

class ConfirmRateData extends Component {
  state = {};

    componentDidMount() {
      const {
        rdx: {
          action: { setNavigationMapStep },
        },
      } = this.props;
      setNavigationMapStep(0);
    }
    _onHandleNextButtonClick = () => {
        const {
          history,
          location: { quote },
        } = this.props;
        const pathname = '/quote';
        history.push({ pathname, quote });
    };

  render() {
    const {
      rdx: {
        state: {
          global: { lang },
        },
      },
    } = this.props;
    return (
      <div className="confirmratedata-container text-center">
          <h1>Placeholder page to confirm data</h1>
            <div className="buttons-wrapper">
            <StyledButton
                id="first-footer-btn"
                text={getTranslatedText("footerButtons.next", lang)}
                onClick={this._onHandleNextButtonClick}
                classType="primary"
            />
            </div>
      </div>
    );
  }
}

ConfirmRateData.propTypes = {
  rdx: PropTypes.shape({}).isRequired,
  history: PropTypes.shape({}).isRequired,
  location: PropTypes.shape({}).isRequired,
};

export default Connect(withRouter(ConfirmRateData));
