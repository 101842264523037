import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import shortid from 'shortid';
// Components
import QuoteBox from './QuoteBox';
import { IconHolder, FooterButtons } from '../../components';
import withQuoteLayout from '../../components/withQuoteLayout';
// Utils
import { getTranslatedText } from './languages';
import Connect from '../../utils/connect';
// Styles
import './styles.css';

class InitialQuote extends Component {
  state = {};

  componentDidMount() {
    const {
      rdx: {
        action: { setNavigationMapStep },
      },
    } = this.props;

    setNavigationMapStep(1);
  }

  onClickCustomizeButton = () => {
    const {
      history,
      location: { search },
    } = this.props;
    const pathname = '/quote/coverages';
    history.push({ pathname, search });
  };

  onClickCreatePolicyButton = () => {
    const {
      history,
      location: { search },
    } = this.props;
    const pathname = '/quote/finalize';
    history.push({ pathname, search });
  };

  renderQuoteCard = (title, boxes) => (
    <QuoteBox title={title}>
      {boxes.map(box => (
        <IconHolder key={shortid.generate()} size="sml" color="primary" Icon="$" text={box} />
      ))}
    </QuoteBox>
  );

  render() {
    const {
      rdx: {
        state: {
          global: {
            localization: { lang },
          },
        },
      },
    } = this.props;

    const coveragesCardTitleText = getTranslatedText('quoteCard.coverages.title', lang);
    const deductiblesCardTitleText = getTranslatedText('quoteCard.deductibles.title', lang);
    const creditsCardTitleText = getTranslatedText('quoteCard.credits.title', lang);

    return (
      <div className="container">
        <section className="quotes">
          {this.renderQuoteCard(coveragesCardTitleText, ['box', 'box', 'box', 'box', 'box', 'box'])}
          {this.renderQuoteCard(deductiblesCardTitleText, ['box', 'box'])}
          {this.renderQuoteCard(creditsCardTitleText, ['box', 'box'])}
        </section>
        <FooterButtons
          firstButtonText={getTranslatedText('footerButtons.customize', lang)}
          secondButtonText={getTranslatedText('footerButtons.create', lang)}
          onClickFirstButton={this.onClickCustomizeButton}
          onClickSecondButton={this.onClickCreatePolicyButton}
        />
      </div>
    );
  }
}

InitialQuote.propTypes = {
  rdx: PropTypes.shape({}).isRequired,
  history: PropTypes.shape({}).isRequired,
  location: PropTypes.shape({}).isRequired,
};

export default withQuoteLayout(Connect(withRouter(InitialQuote)));
