import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
// Redux
import Connect from '../../utils/connect';
// Components
import { Video } from '../../components';
import PropertyAddress from '../PropertyAddress';

// styles
import './Home.css';

class Home extends Component {
  state = {};

  _onClickCheckButton = () => {
    const {
      history,
      location: { search },
    } = this.props;
    const pathname = '/';
    history.push({ pathname, search });
  };

  render() {
    const {
      rdx: {
        state: {
          global: { videoSrc },
        },
      },
    } = this.props;
    return (
      <div className="home-container">
        <Video videoSrc={videoSrc} title="video" />
        <PropertyAddress />
      </div>
    );
  }
}

Home.propTypes = {
  rdx: PropTypes.shape({}).isRequired,
  history: PropTypes.shape({}).isRequired,
  location: PropTypes.shape({}).isRequired,
};

export default Connect(withRouter(Home));
