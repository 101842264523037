import React from 'react';
import PropTypes from 'prop-types';

import './PropertyCard.css';
import '../styles/color.css';

const PropertyCard = props => {
  const { property, description, selected, onClick, onClickMore, buttonName, tabIndex } = props;
  const cardClasses = ['property-card'];

  if (selected) {
    cardClasses.push('selected');
  }

  return (
    <div className={cardClasses.join(' ')}>
      <div className="property-holder">
        <div className="property-title">{property}</div>
        <div className="property-description">{description}</div>
        <div
          className="property-more"
          role="button"
          tabIndex={tabIndex}
          onKeyDown={e => e}
          onClick={onClickMore}
        >
          {'More'}
        </div>
      </div>
      <div
        className="button-holder"
        onKeyDown={e => e}
        onClick={onClick}
        role="button"
        tabIndex={tabIndex}
      >
        {buttonName}
      </div>
    </div>
  );
};

PropertyCard.defaultProps = {
  selected: false,
};

PropertyCard.propTypes = {
  property: PropTypes.oneOfType([PropTypes.element, PropTypes.string]).isRequired,
  description: PropTypes.string.isRequired,
  selected: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  onClickMore: PropTypes.func.isRequired,
  buttonName: PropTypes.string.isRequired,
  tabIndex: PropTypes.number.isRequired,
};

export default PropertyCard;
