import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
// Components
import SearchAddress from './SearchAddress';
import { LoadingScreen, StyledButton } from '../../components';
// Redux
import Connect from '../../utils/connect';
// styles
import styles from './styles.module.css';
// helper
import { sleep } from '../../utils/timeout';

class PropertyAddress extends Component {
  state = {
    loadingScreenTimeout: 0.8,
    typingTimeoutTime: 128,
  };

  typingTimeout = null;

  _typingTimeout = callback => {
    const { typingTimeoutTime } = this.state;
    clearTimeout(this.typingTimeout);
    this.typingTimeout = setTimeout(callback, typingTimeoutTime);
  };

  onClickNextButton = () => {
    const { loadingScreenTimeout } = this.state;
    const index = 0;
    const {
      history,
      rdx: {
        action: { setBannerErrorMessage, displayLoadingScreen, setCurrentMessage, setCurrentTitle },
        state: {
          propertyAddress: {
            search: { value: searchValue },
          },
          initialQuote: { rateParameters },
          insuranceInfo: { messages },
        },
      },
    } = this.props;
    setCurrentMessage(messages[index].text);
    setCurrentTitle(messages[index].title);
    displayLoadingScreen();
    sleep(loadingScreenTimeout)
      .then(() => this._fetchAddressData(searchValue))
      .then(() => this._fetchRateData(rateParameters))
      .then(() => {
        const newIndex = messages.lenght - 1 <= index ? index + 1 : 0;
        setCurrentMessage(messages[newIndex].text);
        setCurrentTitle(messages[newIndex].text);
      })
      .then(() => history.push('/quote/confirm-rate-data'))
      .catch(({ message }) => {
        setCurrentMessage(message);
        setCurrentTitle('Error');
        setBannerErrorMessage(message);
      })
      .finally(async () => {
        await sleep(2 * loadingScreenTimeout);
        displayLoadingScreen(false);
      });
  };

  _fetchAddressData = async address => {
    const { loadingScreenTimeout } = this.state;
    const {
      rdx: {
        action: { searchAddress, setAddressQuote, scrubAddress, describeAddress },
      },
    } = this.props;
    try {
      await searchAddress(address);
      await sleep(loadingScreenTimeout + 2 * Math.random());
      await scrubAddress(address);
      await setAddressQuote(address);
      await describeAddress(address);
    } catch (error) {
      throw error;
    }
  };

  _fetchRateData = async ratingParameters => {
    const {
      rdx: {
        action: { rateQuote },
      },
    } = this.props;
    try {
      await rateQuote(ratingParameters);
    } catch (error) {
      throw error;
    }
  };

  onInputChange = ({ target }) => {
    const {
      rdx: {
        action: { changePAValue, addressTypeahead },
      },
    } = this.props;
    changePAValue('search', 'value', target.value);
    this._typingTimeout(() => addressTypeahead(target.value));
  };

  onClickSuggestion = suggestion => {
    const {
      rdx: {
        action: { changePAValue },
      },
    } = this.props;
    changePAValue('search', 'value', suggestion);
    changePAValue('search', 'suggestions', []);
  };

  renderLoadingComponent = () => {
    const {
      rdx: {
        state: {
          insuranceInfo: { currentMessage, currentTitle },
        },
      },
    } = this.props;

    return <LoadingScreen text={currentMessage} title={currentTitle} />;
  };

  render() {
    const {
      rdx: {
        state: {
          propertyAddress: {
            search: { value: searchValue, suggestions },
            loading,
          },
        },
      },
    } = this.props;

    if (loading) {
      return this.renderLoadingComponent();
    }

    return (
      <div className={`${styles.container} container`}>
        <div className={styles.header}>
          <div className={styles.main}>OpenHouse</div>
          <div className={styles.sub}>Fast. Easy. Simplified</div>
        </div>
        <SearchAddress
          searchValue={searchValue}
          onChange={this.onInputChange}
          onClickSuggestion={this.onClickSuggestion}
          loading={loading}
          options={suggestions}
        />
        <StyledButton
          id="sa-btn-submit"
          disabled={searchValue.length <= 0 || loading}
          color="primary"
          onClick={this.onClickNextButton} text="Next"
        >
        </StyledButton>
      </div>
    );
  }
}

PropertyAddress.propTypes = {
  rdx: PropTypes.shape({}).isRequired,
  history: PropTypes.shape({}).isRequired,
};

export default Connect(withRouter(PropertyAddress));
