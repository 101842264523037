import { handleActions } from 'redux-actions';
import update from 'immutability-helper';

const initialState = {
  messages: [
    {
      title: 'Insurance Information',
      text: 'Placeholder text to describe to the user what is happening behind the scenes.',
    },
    {
      title: 'House Information',
      text: 'Placeholder text with info about why you need home insurance.',
    },
    {
      title: 'Coverage Information',
      text: 'Placeholder text with information about coverages.',
    },
    {
      title: 'Deductible Information',
      text: 'Placeholder text with information about deductibles.',
    },
  ],
  currentMessage: '',
  currentTitle: '',
};

const insuranceInfo = handleActions(
  {
    SET_CURRENT_MESSAGE: (_state, { payload }) =>
      update(_state, { currentMessage: { $set: payload } }),
    SET_CURRENT_TITLE: (_state, { payload }) => update(_state, { currentTitle: { $set: payload } }),
  },
  initialState,
);

export default insuranceInfo;
