import addressParser from 'parse-address';

export const parseAddress = address => {
    
    const parsedAddress = addressParser.parseAddress(address);
    
    let address1 = '';
    let address2 = '';

    address1 += parsedAddress.number;

    address1 += parsedAddress.prefix ? ` ${parsedAddress.prefix} ${parsedAddress.street}`: ` ${parsedAddress.street}`;
    address1 += parsedAddress.type ? ` ${parsedAddress.type}`: '';
    address1 += parsedAddress.suffix ? ` ${parsedAddress.suffix}`: '';

    address2 = parsedAddress.sec_unit_type ? parsedAddress.sec_unit_type : '';
    address2 += parsedAddress.sec_unit_num ? ` ${parsedAddress.sec_unit_num}`: '';

    return {
        address1: address1,
        address2: address2,
        city: parsedAddress.city,
        state: parsedAddress.state,
        postalCode: parsedAddress.zip
    };

};
  