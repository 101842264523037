import update from 'immutability-helper';
import { handleActions } from 'redux-actions';

// InitialStates
const coveragesIS = [
  {
    id: '1',
    price: parseInt(Math.random() * 1000, 10),
    name: 'HOME',
    description: 'This is a short description of the selection.',
  },
  {
    id: '2',
    price: parseInt(Math.random() * 1000, 10),
    name: 'GARAGE',
    description: 'This is a short description of the selection.',
  },
  {
    id: '3',
    price: parseInt(Math.random() * 1000, 10),
    name: 'CONTENTS',
    description: 'This is a short description of the selection.',
  },
  {
    id: '4',
    price: parseInt(Math.random() * 1000, 10),
    name: 'PET',
    description: 'This is a short description of the selection.',
  },
  {
    id: '5',
    price: parseInt(Math.random() * 1000, 10),
    name: 'ACCIDENT',
    description: 'This is a short description of the selection.',
  },
  {
    id: '6',
    price: parseInt(Math.random() * 1000, 10),
    name: 'LIFE',
    description: 'This is a short description of the selection.',
  },
  {
    id: '7',
    price: parseInt(Math.random() * 1000, 10),
    name: 'FIRE',
    description: 'This is a short description of the selection.',
  },
  {
    id: '8',
    price: parseInt(Math.random() * 1000, 10),
    name: 'SINKHOLE',
    description: 'This is a short description of the selection.',
  },
  {
    id: '9',
    price: parseInt(Math.random() * 1000, 10),
    name: 'EXPLOSION',
    description: 'This is a short description of the selection.',
  },
  {
    id: '10',
    price: parseInt(Math.random() * 1000, 10),
    name: 'WINDSTORM',
    description: 'This is a short description of the selection.',
  },
  {
    id: '11',
    price: parseInt(Math.random() * 1000, 10),
    name: 'VOLCANIC',
    description: 'This is a short description of the selection.',
  },
  {
    id: '12',
    price: parseInt(Math.random() * 1000, 10),
    name: 'THEFT',
    description: 'This is a short description of the selection.',
  },
];

const additionalIS = [
  {
    id: '0',
    price: parseInt(Math.random() * 1000, 10),
    name: 'Dwelling',
  },
  {
    id: '1',
    price: parseInt(Math.random() * 1000, 10),
    name: 'Other Structures',
  },
  {
    id: '2',
    price: parseInt(Math.random() * 1000, 10),
    name: 'Personal Property',
  },
  {
    id: '3',
    price: parseInt(Math.random() * 1000, 10),
    name: 'Loss of use',
  },
];

// Reducer state
const initialState = {
  coverages: [...coveragesIS],
  additionalCoverages: [...additionalIS],
};

// Reducer
const coverages = handleActions(
  {
    COVERAGE_SELECT: (_state, { payload }) =>
      update(_state, {
        [payload.arraySelector]: {
          [payload.index]: {
            selected: { $set: !_state[payload.arraySelector][payload.index].selected },
          },
        },
      }),
  },
  initialState,
);

export default coverages;
