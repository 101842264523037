import { createAction } from 'redux-actions';
import { createActionThunk } from 'redux-thunk-actions';

export const changeFQValue = createAction('VALUE_CHANGE_FQ', (field, key, value) => ({
  field,
  key,
  value,
}));

export const submitContactForm = createActionThunk('CONTACT_FORM_SUBMIT', async formValues => {
  return formValues;
});

export const resetFQ = createAction('RESET');
