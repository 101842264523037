import { getTranslationFrom } from '../../utils/translate';

const texts = {
  en: {
    helper: 'Customize your deductibles',
    'footerButtons.back': 'BACK',
    'footerButtons.next': 'NEXT',
    'draggable.hurricane': 'Hurricane',
    'draggable.otherPerils': 'Other perils',
  },
  es: {
    helper: 'Personalize sus deducibles',
    'footerButtons.back': 'ATRAS',
    'footerButtons.next': 'SIGUIENTE',
    'draggable.hurricane': 'Huracan',
    'draggable.otherPerils': 'Otros peligros',
  },
};

export const getTranslatedText = getTranslationFrom(texts);

export default texts;
