import { getTranslationFrom } from '../../utils/translate';

const texts = {
  en: {
    helper: 'Add one or more coverages for your policy',
    'footerButtons.back': 'BACK',
    'footerButtons.next': 'NEXT',
    'cardButton.action': 'SELECT',
  },
  es: {
    helper: 'Agregue una o más coberturas para su póliza',
    'footerButtons.back': 'ATRAS',
    'footerButtons.next': 'SIGUIENTE',
    'cardButton.action': 'ELEGIR',
  },
};

export const getTranslatedText = getTranslationFrom(texts);

export default texts;
