import React from 'react';
import PropTypes from 'prop-types';

import './IconHandler.css';

const IconHolder = props => {
  const { size, color, Icon, text } = props;
  const newColor = color || 'primary';
  const style = `holder ${size} ${newColor}`;
  return <div className={style}>{Icon || text}</div>;
};

IconHolder.propTypes = {
  size: PropTypes.oneOf(['sml', 'med', 'lrg', 'huge']),
  color: PropTypes.string,
  text: PropTypes.string,
  Icon: PropTypes.string,
};

IconHolder.defaultProps = {
  size: 'sml',
  Icon: false,
  color: false,
  text: false,
};

export default IconHolder;
