import React, { Component } from 'react';
import PropTypes from 'prop-types';
import shortid from 'shortid';
// Components
import { Link, withRouter } from 'react-router-dom';
// Styles
import './style.scss';
import logo from '../Images/logo.svg';

class Navbar extends Component {
  state = {
    links: [{ text: 'HOME', href: '/' }, { text: 'Help', href: '/help' }],
  };

  renderLinks = () => {
    const {
      location: { search },
    } = this.props;
    const { links } = this.state;
    return links.map(({ text, href }) => (
      <li key={shortid.generate()}>
        <Link to={`${href}${search}`} className="link">
          {text}
        </Link>
      </li>
    ));
  };

  render() {
    return (
      <div className="navbar-custom">
        <img src={logo} alt="OpenHouse" />
        <ul className="navbar-container">{this.renderLinks()}</ul>
      </div>
    );
  }
}

Navbar.propTypes = {
  location: PropTypes.shape({}).isRequired,
};

export default withRouter(Navbar);
